import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import { Primary, Parallax } from "../../components/Section";
import HeroTropicalSunset from "../../images/hero-tropical-sunset.jpg";

const Thanks = () => {
  return (
    <section>
      <Parallax backgroundImage={HeroTropicalSunset} backgroundPositionY={75}>
        <Row>
          <Col xs={12}>
            <h1>Thank you!</h1>
          </Col>
        </Row>
      </Parallax>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <h3>Message Sent!</h3>
            <p>
              Your message was sent successfully! We will be in touch with you
              shortly.
            </p>
          </Col>
        </Row>
      </Primary>
    </section>
  );
};

export default Thanks;
