import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "react-styled-flexboxgrid";
import Container from "../Container";

const defaultProps = {
  centered: false,
};

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
};
const SectionAlternate = styled.section`
  padding: 5rem 1rem;
  background-color: ${({ theme }) => theme.global.colors.codGray};
  color: ${({ theme }) => theme.global.colors.silverChalice};
  text-align: ${(props) => (props.textCentered ? "center" : "initial")};
  h1,
  h2,
  h3,
  h4,
  h5,
  blockquote {
    color: ${({ theme }) => theme.global.colors.white};
  }
  h1 {
    font-size: 58px;
    font-weight: 700;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
  }
  strong {
    color: ${({ theme }) => theme.global.colors.greenApple};
  }
`;

const Alternate = (props) => {
  const { children, centered } = props;
  return (
    <SectionAlternate textCentered={centered}>
      <Container>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Container>
    </SectionAlternate>
  );
};

Alternate.defaultProps = defaultProps;
Alternate.propTypes = propTypes;

export default Alternate;
