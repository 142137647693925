import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "react-styled-flexboxgrid";
import Container from "../Container";
import defaultBgImg from "../../images/default-bg.jpg";

const defaultProps = {
  centered: true,
  backgroundImage: defaultBgImg,
  backgroundHeight: 400,
  backgroundPositionY: 50,
};

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
  backgroundImage: PropTypes.node,
  backgroundHeight: PropTypes.number,
  backgroundPositionY: PropTypes.number,
};
const SectionParallax = styled.section`
  padding: 5rem 1rem;
  background-color: ${({ theme }) => theme.global.colors.codGray};
  background: url(${(props) => props.bgImage}) no-repeat center center;
  background-size: cover;
  background-position-y: ${(props) => props.bgPosY}%;
  text-align: ${(props) => (props.textCentered ? "center" : "initial")};
  min-height: ${(props) => props.bgHeight}px;
  color: white;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }
  h1 {
    font-size: 58px;
    font-weight: 700;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
  }
  strong {
    color: ${({ theme }) => theme.global.colors.greenApple};
  }
`;

const Parallax = (props) => {
  const {
    children,
    centered,
    backgroundImage,
    backgroundHeight,
    backgroundPositionY,
  } = props;
  return (
    <SectionParallax
      textCentered={centered}
      bgImage={backgroundImage}
      bgHeight={backgroundHeight}
      bgPosY={backgroundPositionY}
    >
      <Container>
        <Row middle="xs">
          <Col xs={12}>{children}</Col>
        </Row>
      </Container>
    </SectionParallax>
  );
};

Parallax.defaultProps = defaultProps;
Parallax.propTypes = propTypes;

export default Parallax;
