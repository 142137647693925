import React from "react";
import Layout from "../components/Layout";
import Thanks from "../components/Thanks";

const ThanksPage = () => (
  <Layout>
    <Thanks />
  </Layout>
);

export default ThanksPage;
